.eligibility-details-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 52px);
    padding: 1.5625vw 0 0;

    .eligibility-details-view {
        height: 100%;
        width: 100%;

        .eligibility-details-header {
            display: flex;
            gap: 1.25vw;

            padding: 0 1.5625vw 1.0417vw;

            color: var(--Header-Text, #646464);
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Manrope;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.53px;

            .back-icon {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }

            .eligibility-basic-info {
                display: flex !important;
                flex-direction: column !important;
                gap: 0.417vw;
            }


            .eligibility-id {
                display: flex;
                padding: 0.2vw 0.3125vw;
                justify-content: center;
                align-items: center;
                gap: 0.52vw;
                border-radius: 4px;
                background: var(--Selection-BG, #F6F6F6);

                font-family: Manrope;
            }

            .eligibility-title {
                color: #333;
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: Manrope;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                letter-spacing: 0.53px;
            }

            .eligibility-date-time {
                display: flex;
                gap: 0.20vw;

                color: #878787;
                font-family: Manrope;
                text-transform: uppercase;
            }
        }

        .eligibility-details-body {
            display: flex !important;
            flex-direction: column !important;
            gap: 1.0417vw;
            height: calc(100% - 102px) !important;
            overflow: auto;

            .ant-row,
            .ant-col {
                color: var(--Primary-Text-Color, #333);
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
            }

            .eligibility-check-body-div {
                display: flex;
                flex-direction: column;
                padding: 0px 1.5625vw 1.25vw 4.0625vw;
                align-items: flex-start;
                gap: 1.25vw;
                align-self: stretch;

                .card-container,
                .details-container {
                    display: flex;
                    align-items: flex-start;
                    gap: 1.0417vw;
                    align-self: stretch;
                }

                .card {
                    display: flex;
                    flex-direction: row;
                    width: 269px;
                    height: 150px;
                    padding: 0.834vw 1.0417vw;
                    align-items: flex-start;
                    gap: 0.625vw;

                    border-radius: 12px;
                    border: 1px solid var(--Primary-Color, #006FE6);
                    background: var(--White, #FFF);
                    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
                }

                .card-details {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 152px;
                    gap: 20px;
                }

                .card-title {
                    min-height: 40px;
                    align-self: stretch;
                    margin: 0 !important;

                    span {
                        font-weight: 800;
                        line-height: 20px;
                        letter-spacing: 0.085px;
                    }
                }

                .status-time {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 8px;

                    .status {
                        display: flex;
                        width: 104px;
                        padding: 0.365vw 0.52vw;
                        justify-content: center;
                        align-items: center;

                        font-weight: 400;
                        line-height: 15.247px;
                        letter-spacing: 0.53px;
                        text-transform: uppercase;
                    }

                    .active {
                        border-radius: 5px;
                        border: 1px solid var(--Status-Stroke, #97FF5B);
                        background: var(--Status-Color, #DAFEC5);
                    }
                }

                .image-div {
                    display: flex;
                    align-items: center;
                    height: 100%;
                }

                .card-image {
                    width: 65px;
                    /* height: 76.071px; */
                }

                .amount {
                    font-size: 28px;
                    font-weight: 800;
                    line-height: 32px;
                    letter-spacing: 0.148px;
                }

                .sub-text {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0.069px;
                }

                .ant-table-wrapper {
                    width: 100%;
                }

                .ant-table-thead>tr>th {
                    color: var(--Secondary-Text-Color, #757575);
                    font-feature-settings: 'liga' off, 'clig' off;
                    font-family: Manrope;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                    letter-spacing: 0.53px;
                    text-transform: uppercase;
                }

                .ant-table-tbody>tr>td {
                    color: var(--Primary-Text-Color, #333);
                    font-feature-settings: 'liga' off, 'clig' off;
                    font-family: Manrope;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0.53px;
                }

                .ant-table-thead>tr>th:nth-child(1) {
                    min-width: 120px;
                }

                .ant-table-thead>tr>th {
                    padding: 14px;
                }

                .ant-table-tbody>tr>td {
                    padding: 14px;
                }

                .ant-table-thead>tr>th {
                    background: #F3F4F8;
                }

                .ant-table-tbody>tr>td {
                    border-bottom: 1px solid #BABABA;
                }

                .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
                    background-color: unset !important;
                }

                .deductible-table .ant-table-thead>tr:nth-child(1)>th {
                    color: #333;
                    font-weight: 600;
                    background: #fff;
                }

                .deductible-table .ant-table-thead>tr:nth-child(2)>th {
                    background: #F3F4F8;
                }

                .deductible-table .ant-table-thead>tr:nth-child(2)>th:nth-child(3),
                .deductible-table .ant-table-thead>tr:nth-child(2)>th:nth-child(4) {
                    color: #333;
                    background: #A6C6DB;
                }

                .deductible-table .ant-table-tbody>tr>td:nth-child(3),
                .deductible-table .ant-table-tbody>tr>td:nth-child(4) {
                    background: #E0F4FF;
                }
            }

            .edit-button-row {
                display: flex;
                width: 100%;
                justify-content: end;
            }

            .edit-icon {
                width: 24px;
                cursor: pointer;
            }

            .details-text {
                font-weight: 600;
                line-height: 15.247px;
                letter-spacing: 0.53px;
            }

            .details-div {
                display: flex;
                width: 269px;
                height: 95px;
                min-height: 95px;
                padding: 0.625vw 0.834vw;
                align-items: flex-start;
                gap: 0.625vw;
                flex: 1 0 0;

                border-radius: 6px;
                background: var(--Selection-BG, #F6F6F6);
            }

            .field-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
            }

            .field-header {
                color: var(--Secondary-Text-Color, #757575);
                font-size: 14px;
                font-weight: 500;
                line-height: 15.247px;
                letter-spacing: 0.53px;
                text-transform: uppercase;
            }

            .field-data {
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.085px;
            }

            .no-plan {
                font-family: Manrope;
                color: var(--Secondary-Text-Color, #757575);
                font-size: 16px;
                font-weight: 400;
                line-height: 15.247px;
                letter-spacing: 0.53px;
            }

            .note {
                font-size: 10px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.053px;
            }
        }

    }
}

.add-eligibility-check-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 52px);
    padding: 1.5625vw 0 0;

    .add-eligibility-check-view {
        height: 100%;
        width: 100%;

        .add-eligibility-check-header {
            display: flex;
            align-items: center;
            gap: 1.25vw;
            padding: 0 1.5625vw 1.0417vw;

            .back-icon {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
        }

        .add-eligibility-check-header>span {
            color: #333;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Manrope;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 15.247px;
            /* 76.233% */
            letter-spacing: 0.53px;
        }

        .add-eligibility-check-body {
            padding: 0 1.5625vw 1.25vw 4vw;
            height: calc(100% - 44px);
            overflow: auto;

            .eligibility-form {
                display: flex !important;
                flex-direction: column !important;
                gap: 1.25vw;
            }

            .add-eligibility-form-row {
                display: flex;
                gap: 0.8265vw;
                flex: 1 0 0;

                .ant-col {
                    display: flex !important;
                    flex-direction: column !important;
                    gap: 0.417vw;
                }
            }


            .form-input {
                width: 272.6px;
                height: 46px;
                flex-shrink: 0;
                border-radius: 3px;
                border: 1px solid #DADADA;
                /* background: #FFF; */

                .calendar-icon {
                    width: 24px;
                }
            }

            .ant-select-selection-placeholder {
                overflow: hidden;
                color: var(--Line, #BABABA);
                text-overflow: ellipsis;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .ant-select .ant-select-selection-item,
            .ant-form-item-control-input-content>input,
            .ant-picker-input>input {
                color: #333;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .form-header {
                color: var(--Text-Color, #333);
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .eligibility-check-details-row {
                gap: 1.0417vw;
            }

        }

    }
}

.approval-denial {
    position: absolute;
    left: -294px;
    bottom: 0px;
    display: flex !important;
    flex-direction: column !important;
    gap: 10px;

    .info-div {
        display: flex;
        width: 216px;
        padding: 8px 0px 4px 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
    }

    .info-data {
        color: var(--Primary-Text-Color, #333);
        font-family: Manrope;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .info-icons {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

.approval-denial .approval {
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 10px;
    background: var(--light-green-lighten-5, #F1F8E9);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);

    .info-header {
        color: var(--Success-message, #27AE60);
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.approval-denial .denial {
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 10px;
    background: var(--red-lighten-5, #FFEBEE);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);

    .info-header {
        color: var(--Tertiary-Color, #F82D18);
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.approval-denial>div>img {
    width: 18px;
    padding: 8px 0 4px 0;
}

.approval-denial>div>div>img {
    width: 18px;
    cursor: pointer;
}

.add-button {
    display: flex;
    min-width: 100px;
    height: unset;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    background: #006FE6;

    color: #FFF;
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}

.add-button:hover {
    background: #006FE6 !important;
    color: #FFF !important;
}

.react-tel-input .form-control {
    padding: 10px 60px;
}