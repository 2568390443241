.appointments-main-div {
  height: 70vh;

  .rbc-time-view .rbc-header {
    height: 111px;
    padding: 12px 16px;
    span {
      display: flex;
      color: var(--Secondary-Text-Color, #757575);
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 15.247px; /* 108.904% */
      letter-spacing: 0.53px;
      text-transform: uppercase;
    }
  }

  .rbc-month-view {
    .rbc-header {
      padding: 12px 16px;
      display: flex;
      span {
        display: flex;
        color: var(--Secondary-Text-Color, #757575);
        font-feature-settings: "liga" off, "clig" off;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 15.247px; /* 108.904% */
        letter-spacing: 0.53px;
        text-transform: uppercase;
      }
    }
    .rbc-date-cell {
      padding: 3px;
      /* flex: 1 1; */
      /* min-width: 0; */
      padding-right: 5px;
      text-align: left;
      button {
        color: var(--Primary-Text-Color, #333);
        font-feature-settings: "liga" off, "clig" off;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px; /* 137.5% */
        letter-spacing: 0.53px;
      }
    }
  }

  .rbc-month-view {
    border: none;
  }

  .time-change-segmented {
    .ant-segmented .ant-segmented-group {
      gap: 4px;
    }

    .ant-segmented .ant-segmented-item {
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Secondary-Text-Color, #979797);
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.53px;
    }

    .ant-segmented .ant-segmented-item:hover {
      color: var(--Secondary-Text-Color, #979797) !important;
    }

    .ant-segmented .ant-segmented-item-selected {
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background: var(--Primary-Color, #006fe6);
      color: var(--Button-Text-color, #fff);
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.53px;
    }

    .ant-segmented .ant-segmented-item-selected :hover {
      color: var(--Button-Text-color, #fff) !important;
    }

    .ant-segmented .ant-segmented-thumb {
      height: 34px;
      color: var(--Button-Text-color, #fff);
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.53px;
      background: var(--Primary-Color, #006fe6);
      /* transition: all 103s ease-in-out; */
    }

    .ant-segmented .ant-segmented-item-label {
      min-height: 28px;
      line-height: 28px;
      overflow: hidden;
      padding: 8px 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .sync-text {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.247px; /* 95.291% */
    letter-spacing: 0.53px;
  }

  .datepicker {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.247px; /* 108.904% */
    letter-spacing: 0.53px;
    text-transform: uppercase;
  }

  .rbc-timeslot-group {
    min-height: 60px;
  }

  .rbc-time-header-content {
    height: 88px;
    border: none;
  }
  .rbc-today {
    background: none;
  }

  .rbc-time-content {
    border-top: 1px solid #ddd;
  }

  .rbc-time-view {
    border: none;
  }

  .rbc-time-gutter .rbc-timeslot-group {
    border-bottom: none;
  }

  /* .rbc-event,
  .rbc-day-slot .rbc-background-event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 2px 5px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left;
  } */
  .rbc-day-slot .rbc-event,
  .rbc-day-slot .rbc-background-event {
    border-radius: 5px;
    border-top: 1px solid #006fe6;
    border-right: 1px solid #006fe6;
    border-bottom: 1px solid #006fe6;
    border-left: 4px solid #006fe6;
    background: #d7eefe;
    overflow: hidden;
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    text-overflow: ellipsis;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 15.247px; /* 108.904% */
    letter-spacing: 0.53px;
    display: flex;
    padding: 3px 8px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }

  .rbc-month-view .rbc-event,
  .rbc-month-view .rbc-background-event {
    border-radius: 5px;
    border-top: 1px solid #006fe6;
    border-right: 1px solid #006fe6;
    border-bottom: 1px solid #006fe6;
    border-left: 4px solid #006fe6;
    background: #d7eefe;
    overflow: hidden;
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    text-overflow: ellipsis;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 15.247px; /* 108.904% */
    letter-spacing: 0.53px;
    display: flex;
    padding: 3px 8px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }

  .custom-event-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    gap: 2px;
    .event-title {
      overflow: hidden;
      color: #333;
      font-feature-settings: "liga" off, "clig" off;
      text-overflow: ellipsis;
      font-size: 14px;
      font-family: Manrope;
      font-style: normal;
      font-weight: 700;
      line-height: 15.247px; /* 108.904% */
      letter-spacing: 0.53px;
    }
    .event-time {
      overflow: hidden;
      color: #333;
      font-feature-settings: "liga" off, "clig" off;
      text-overflow: ellipsis;
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 116.667% */
      letter-spacing: 0.53px;
    }
  }
}

.rbc-day-slot .rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
  display: none;
}

.event-popover {
  width: 487.629px;
  max-height: 705px;
  display: flex;
  flex-direction: column;
  gap: 8;
  .event-id {
    border-radius: 4px;
    background: var(--amber-lighten-4, #ffecb3);
    display: flex;
    padding: 4px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--brown-lighten-1, #8d6e63);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.247px; /* 108.904% */
    letter-spacing: 0.53px;
    text-transform: uppercase;
  }
  .treatment-name {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 110% */
    letter-spacing: 0.53px;
  }
  .treatment-time {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
    letter-spacing: 0.53px;
  }
  .event-text-header {
    color: #878787;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.247px; /* 108.904% */
    letter-spacing: 0.53px;
    text-transform: uppercase;
  }
  .event-text {
    color: var(--Primary-Text-Color, #333);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.709px; /* 116.932% */
    letter-spacing: 0.085px;
  }
  .treatment-div {
    display: flex;
    padding: 12px;
    justify-content: space-between;
    /* flex-direction: column; */
    /* align-items:center; */
    /* gap: 12px; */
    /* align-self: stretch; */
    border-radius: 6px;
    background: var(--BG, #f3f4f8);
    width: 100%;
    /* margin: 0 0 0 20px; */
    .event-text-description {
      color: var(--Secondary-Text-Color, #757575);
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18.709px; /* 133.637% */
      letter-spacing: 0.074px;
    }
  }
  .patient-name {
    color: var(--Primary-Text-Color, #333);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16.2px; /* 101.25% */
    letter-spacing: 0.085px;
  }
  .patient-id {
    border-radius: 4px;
    background: var(--Secondary-Color, #d7eefe);
    display: flex;
    padding: 4px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--Primary-Color, #006fe6);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.247px; /* 108.904% */
    letter-spacing: 0.53px;
    text-transform: uppercase;
  }
}

.custom-toolbar-main-div {
  .sync-time {
    color: var(--Secondary-Text-Color, #757575);
    text-align: right;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.247px; /* 108.904% */
    letter-spacing: 0.53px;
  }
  .dropdown-title {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    letter-spacing: 0.53px;
  }
}
