.patients-main-div {
  width: 100%;
  height: calc(100% - 52px);
  padding: 0.5vw 1.5625vw 1.5625vw;

  .data-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: Manrope;

    .sync-time {
      display: flex;
      justify-content: end;
      position: relative;
      top: -32px;

      color: var(--Secondary-Text-Color, #757575);
      text-align: right;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 15.247px;
      /* 108.904% */
      letter-spacing: 0.53px;
    }

    .ant-tabs-top>.ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs .ant-tabs-tab,
    .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
      margin: 0;
      padding: 0.99vw 0.834vw;
    }

    .ant-tabs-tab .ant-tabs-tab-active {
      color: #006fe6;
    }

    .ant-divider-horizontal {
      background: var(--Line, #d9d9d9);
      height: 1px;
      margin: 0 0 12px 0;
    }

    .ant-tabs-tab {
      color: var(--Secondary-Text-Color, #979797);
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.53px;
    }

    .ant-tabs-tab-active {
      font-weight: 600 !important;
    }

    .ant-spin-spinning {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .data-header {
    display: flex;
    justify-content: end;
    align-items: center;
    /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
    font-size: 13px;
    height: 56px;
  }

  .name-container {
    display: flex !important;
    align-items: center;
    width: 100%;
    gap: 0.6vw;

    .initials {
      width: 42.52px;
      height: 42.52px;
      display: flex !important;
      justify-content: center;
      align-items: center !important;
      background: #d9d9d9;
      border-radius: 50%;
      color: #333;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 15.247px;
      letter-spacing: 0.106px;
    }
  }

  .row-data-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: 100%;
    width: 100%;
    gap: 5px;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    line-height: 15.247px;
    letter-spacing: 0.53px;

    .clinic-name {
      color: var(--Primary-Color, #006fe6);
      font-weight: 500;
      letter-spacing: 0.085px;
      /* text-decoration-line: underline; */
    }

    .full-name {
      align-items: center;
      width: calc(100% - 55px);
      color: #006fe6;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.085px;
      text-transform: capitalize;
      text-decoration-line: underline;
    }

    .email {
      max-width: 100%;
      color: var(--Primary-Color, #006fe6);
      font-weight: 400;
      line-height: 22px;
      text-decoration-line: underline;
      /* text-transform: lowercase; */
      cursor: pointer;
    }

    .phone {
      font-weight: 400;
    }

    .status {
      display: flex;
      padding: 7px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      text-align: center;
      color: #333;
      font-weight: 400;
      text-transform: uppercase;
    }

    .status-pending {
      border: 1px solid #ffb429;
      background: #ffdd9c;
    }

    .status-active {
      border: 1px solid var(--Status-Stroke, #97ff5b);
      background: var(--Status-Color, #dafec5);
    }

    .expired {
      color: #ff3e3e;
      line-height: 22px;
    }
  }

  .book-app-text {
    cursor: pointer;

    color: #006fe6;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    word-wrap: break-word;
  }

  .ellipsis-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .ag-theme-quartz {
    --ag-borders: none;
    --ag-active-color: none;
    --ag-range-selection-border-color: #fff;

    .ag-column-last {
      justify-content: end;

      .ag-cell-wrapper {
        width: unset;
      }

      .ag-header-cell-label {
        justify-content: end;
      }
    }

    .ag-ltr .ag-cell {
      border-right-width: 1px;
      display: flex;
    }

    .ag-header-cell-sortable .ag-header-cell-label,
    .ag-header-cell-text {
      color: rgba(135, 135, 135, 0.85);
      font-feature-settings: "liga" off, "clig" off;
      font-family: var(--font-family);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.53px;
      text-transform: uppercase;
    }
  }

  .propic {
    /* border-radius: var(--Number, 42.521px); */
    border-radius: 50%;
    width: 42.521px;
    height: 42.521px;
    /* background: url(<path-to-image>) lightgray 50% / cover no-repeat; */
    flex-shrink: 0;

    .text {
      color: var(--Primary-Text-Color, #333);
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 15.247px;
      /* 76.233% */
      letter-spacing: 0.106px;
    }
  }

  .no-propic {
    border-radius: 50%;
    width: 42.52px;
    height: 42.52px;
    background: #d9d9d9;
    flex-shrink: 0;
  }

  .clickable-name {
    color: var(--Primary-Color, #006fe6);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: var(--font-size-h3, 16px);
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 125% */
    letter-spacing: 0.085px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    cursor: pointer;
    padding: 0 11px;
  }

  .clickable-email {
    cursor: pointer;
    overflow: hidden;
    color: var(--Primary-Color, #006fe6);
    font-feature-settings: "liga" off, "clig" off;
    text-overflow: ellipsis;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.247px;
    /* 95.291% */
    letter-spacing: 0.53px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }

  .patient-search-input {
    width: 280px;
    border-radius: 5px;
    border: 1px solid var(--Line, #bababa);
    background: var(--White, #fff);
  }

  .search-input {
    /* padding: 10px 0; */
    display: flex;
    width: 280px;
    height: 35px;
    /* padding: 5px 12px; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* gap: 10px; */
    border-radius: 5px;
    border: var(--Number, 1px) solid var(--Line, #bababa);
    /* background: var(--White, #fff); */
    /* overflow: hidden; */
    color: #bababa;
    /* font-feature-settings: "liga" off, "clig" off; */
    text-overflow: ellipsis;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.247px;
    letter-spacing: 0.53px;
    text-transform: uppercase;
  }

  .sync-text {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.247px;
    /* 95.291% */
    letter-spacing: 0.53px;
  }

  .table-content {
    color: var(--Primary-Text-Color, #333);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.247px;
    /* 95.291% */
    letter-spacing: 0.53px;
  }
}

.ag-root-wrapper,
.ag-root-wrapper-body {
  height: 100% !important;
}

.add-patient-modal {
  .add-patient-text {
    color: var(--Text-Color, #333);
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .input {
    border-radius: 3px;
    border: var(--Number, 1px) solid #dadada;
    background: #fff;
    display: flex;
    height: 46px;
    align-items: center;
    align-self: stretch;
    overflow: hidden;
    color: var(--Secondary-Text-Color, #757575);
    text-overflow: ellipsis;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 8px 0 0 0;
  }

  .ant-drawer-body {
    padding: 1.25vw !important;
  }

  .add-patient-modal-main-div {
    height: 100%;
    overflow: auto;

    .ant-spin-nested-loading {
      height: calc(100% - 48px);
    }

    .ant-spin-container{
      height: 100%;
    }

    .ant-form {
      height: 100%;
      display: flex !important;
      flex-direction: column !important;
      justify-content: space-between;
    }

    .form-control {
      color: #333;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 15.247px;
      letter-spacing: 0.53px;
      text-transform: uppercase;
      padding: 0 70px;
      border: none;
    }

    .form-control:focus {
      box-shadow: none;
    }

    .cancel-button {
      display: flex;
      min-width: 100px;
      height: unset;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 5px;
      border: 1px solid #006FE6;
      background: #FFF;

      color: #006FE6;
      text-align: center;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      /* 137.5% */
  }

  .add-button {
      display: flex;
      min-width: 100px;
      height: unset;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 5px;
      background: #006FE6;

      color: #FFF;
      text-align: center;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
  }
  }
}

.ant-spin-nested-loading >div>.ant-spin{
  max-height: unset !important;
}

.patient-details-tab-main-div {
  width: 100%;
  height: calc(100% - 52px) !important;

  .tabs-div {
    height: calc(100% - 125px);
  }

  .tabs-div.active {
    height: calc(100% - 224px);
  }

  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-content-holder {
    height: unset !important;
  }

  .back-icon,
  .more-icon,
  .edit-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .options {
    display: flex;
    gap: 1.25vw;
  }

  .patient-details-row {
    padding: 30px 30px 30px 30px;
    border-bottom: 1px solid var(--Line, #d9d9d9);
  }

  .profile-pic {
    width: 58px;
    height: 58px;
  }

  .data-column {
    width: 235px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 8px;
  }

  .name {
    color: var(--Primary-Text-Color, #333);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16.2px;
    /* 101.25% */
    letter-spacing: 0.085px;
  }

  .data-header {
    color: #878787;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.247px;
    /* 108.904% */
    letter-spacing: 0.53px;
    text-transform: uppercase;
    width: 159px;
  }

  .data {
    color: var(--Primary-Text-Color, #333);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.2px;
    /* 101.25% */
    letter-spacing: 0.085px;
  }

  .data-link {
    color: #006fe6;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.247px;
    /* 95.291% */
    letter-spacing: 0.53px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }

  .data-blue {
    color: var(--Primary-Color, #006fe6);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.247px;
    /* 95.291% */
    letter-spacing: 0.53px;
  }

  .phone-icon,
  .email-icon {
    width: 24px;
    height: 24px;
  }

  .copy-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  .status {
    display: flex;
    padding: 7px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid var(--Status-Stroke, #97ff5b);
    background: var(--Status-Color, #dafec5);
    width: 80px;
  }

  .form-control {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.247px;
    letter-spacing: 0.53px;
    text-transform: uppercase;
    padding: 0;
    border: none;
  }

  .form-control:focus {
    box-shadow: none;
  }
}

.appointments-container {
  .ag-theme-quartz {
    padding: 12px 0 0 12px;
    height: 100%;
    --ag-borders: none;
    --ag-active-color: none;
    --ag-range-selection-border-color: #fff;

    .ag-header-cell {
      padding: 0 8px !important;
    }

    .ag-column-last {
      width: 118px !important;
      justify-content: end;

      .ag-cell-wrapper {
        width: unset;
      }

      .ag-header-cell-label {
        justify-content: end;
      }
    }

    .ag-pinned-right-header,
    .ag-pinned-right-cols-container {
      width: 118px !important;
      min-width: 118px !important;

      .ag-header-row {
        width: 118px !important;
      }
    }

    .ag-ltr .ag-cell {
      border-right-width: 1px;
      display: flex;
    }

    .ag-header-cell-sortable .ag-header-cell-label,
    .ag-header-cell-text {
      color: rgba(135, 135, 135, 0.85);
      font-feature-settings: "liga" off, "clig" off;
      font-family: var(--font-family);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.53px;
      text-transform: uppercase;
    }
  }

  .appointment-list {
    height: calc(100% - 68px);
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}

.patient-claim-data-main-div {
  .medical-history-ag {
    height: calc(100% - 68px);
    overflow: auto;
  }

  .ag-theme-quartz {
    padding: 12px 0 12px 12px;
    height: 100%;
    --ag-borders: none;
    --ag-active-color: none;
    --ag-range-selection-border-color: #fff;

    .ag-column-last {
      /* justify-content: end; */

      .ag-cell-wrapper {
        width: unset;
      }

      .ag-header-cell-label {
        /* justify-content: end; */
      }
    }

    .ag-ltr .ag-cell {
      border-right-width: 1px;
      display: flex;
      color: var(--Primary-Text-Color, #333);
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.53px;
    }

    .ag-header-cell-sortable .ag-header-cell-label,
    .ag-header-cell-text {
      color: rgba(135, 135, 135, 0.85);
      font-feature-settings: "liga" off, "clig" off;
      font-family: var(--font-family);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.53px;
      text-transform: uppercase;
    }
  }

  height: 100%;

  .medical-history-text {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.247px;
    /* 76.233% */
    letter-spacing: 0.53px;
  }

  .medical-history-header-div {
    display: flex;
    height: 68px;
    padding: 0px 30px;
    align-items: center;
    gap: 30px;
    border-bottom: 1px solid var(--Line, #bababa);
  }

  .Add-Medical-History-btn {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.247px;
    /* 95.291% */
    letter-spacing: 0.53px;
    padding: 20px;
  }
}

.medical-history-main-div,
.claim-details-main-div {
  .medical-history-ag {
    height: calc(100% - 68px);
    overflow: auto;
  }

  height: 100%;

  .ag-theme-quartz {
    padding: 12px 0 12px 12px;
    height: 100%;
    --ag-borders: none;
    --ag-active-color: none;
    --ag-range-selection-border-color: #fff;

    .ag-column-last {
      justify-content: end;

      .ag-cell-wrapper {
        width: unset;
      }

      .ag-header-cell-label {
        justify-content: end;
      }
    }

    .ag-ltr .ag-cell {
      border-right-width: 1px;
      display: flex;
      color: var(--Primary-Text-Color, #333);
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.53px;
    }

    .ag-header-cell-sortable .ag-header-cell-label,
    .ag-header-cell-text {
      color: rgba(135, 135, 135, 0.85);
      font-feature-settings: "liga" off, "clig" off;
      font-family: var(--font-family);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.53px;
      text-transform: uppercase;
    }
  }

  .medical-history-text {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.247px;
    /* 76.233% */
    letter-spacing: 0.53px;
  }

  .medical-history-header-div {
    display: flex;
    height: 68px;
    padding: 0px 30px;
    align-items: center;
    gap: 30px;
    border-bottom: 1px solid var(--Line, #bababa);
  }

  .Add-Medical-History-btn {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.247px;
    /* 95.291% */
    letter-spacing: 0.53px;
    padding: 20px;
  }
}

.ag-header {
  height: 52px !important;
  min-height: 52px !important;
  border-radius: 5px 0px 0px 5px;
  background: var(--BG, #f3f4f8);
}

.row-data-element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100%;
  width: 100%;
  gap: 5px;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0.53px;

  .app-id {
    color: var(--Primary-Color, #006fe6);
    font-weight: 500;
    letter-spacing: 0.085px;
    text-decoration-line: underline;
  }

  .name-specialization {
    display: flex !important;
    flex-direction: column !important;
    width: calc(100% - 55px);
    gap: 0.417vw;
  }

  .full-name {
    max-width: 100%;
  }

  .date-time {
    display: flex !important;
    flex-direction: column !important;
  }
}

.actions {
  display: flex !important;
  gap: 1.25vw;

  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.add-medical-history {
  color: var(--Text-Color, #333);
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.record-id {
  color: #006fe6;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  text-decoration-line: underline;
}

.insurance-data-main-div {
  padding: 30px;
}

.insurance-data-main-div,
.patient-claim-data-main-div {
  .mem-id-div {
    /* gap: 20px; */
    border-radius: 4px;
    margin-bottom: 20px;
    background: var(--Selection-BG, #f6f6f6);
    display: flex;
    padding: 4px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--Header-Text, #646464);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.247px;
    /* 108.904% */
    letter-spacing: 0.53px;
    text-transform: uppercase;
    width: 120px;
  }

  .data-column {
    width: 235px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .data-header {
    color: #878787;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.247px;
    /* 108.904% */
    letter-spacing: 0.53px;
    text-transform: uppercase;
    width: 159px;
  }

  .data {
    color: var(--Primary-Text-Color, #333);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.2px;
    /* 101.25% */
    letter-spacing: 0.085px;
  }
}

.patient-claim-data-main-div {
  .treatment-name {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.247px;
    /* 76.233% */
    letter-spacing: 0.53px;
  }

  .ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #000;
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    width: 75px;
    text-align: center;
  }

  .ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title,
  .ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    width: 75px;
    color: var(--Primary-Text-Color, #333);
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 125% */
    text-align: center;
  }

  .ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: var(--Primary-color, #006fe6);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
  }

  .ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: var(--custom-warning, #fb8c00);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
  }

  .ant-steps.ant-steps-dot .ant-steps-item-content,
  .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-content {
    background: #fff;
  }

  .ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: var(--Success-message, #27ae60);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
  }

  .medical-history-ag {
    height: 22vh;
    overflow: auto;
  }

  .ag-theme-quartz {
    height: 100%;
    --ag-borders: none;
    --ag-active-color: none;
    --ag-range-selection-border-color: #fff;

    .ag-column-last {
      /* justify-content: end; */

      .ag-cell-wrapper {
        width: unset;
      }

      .ag-header-cell-label {
        /* justify-content: end; */
      }
    }

    .ag-ltr .ag-cell {
      border-right-width: 1px;
      display: flex;
      color: var(--Primary-Text-Color, #333);
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.53px;
    }

    .ag-header-cell-sortable .ag-header-cell-label,
    .ag-header-cell-text {
      color: rgba(135, 135, 135, 0.85);
      font-feature-settings: "liga" off, "clig" off;
      font-family: var(--font-family);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.53px;
      text-transform: uppercase;
    }
  }

  .medical-history-text {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.247px;
    /* 76.233% */
    letter-spacing: 0.53px;
  }

  .medical-history-header-div {
    display: flex;
    height: 68px;
    padding: 0px 30px;
    align-items: center;
    gap: 30px;
    border-bottom: 1px solid var(--Line, #bababa);
  }

  .Add-Medical-History-btn {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.247px;
    /* 95.291% */
    letter-spacing: 0.53px;
    padding: 20px;
  }

  .eligibility-check-verification-status-row {
    height: 40vh;
  }

  .eligibility-check-text {
    color: var(--Primary-Text-Color, #333);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: var(--font-size-h3, 16px);
    font-style: normal;
    font-weight: 600;
    line-height: 15.247px; /* 95.291% */
    letter-spacing: 0.53px;
    padding: 16px 0;
  }
}

.patient-claim-data-main-div,
.claim-details-main-div {
  .eligibility-check-status-approved {
    border-radius: 5px;
    border: 1px solid var(--Status-Stroke, #97ff5b);
    background: var(--Status-Color, #dafec5);
    display: flex;
    padding: 7px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: var(--font-size-h3, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: 15.247px; /* 95.291% */
    letter-spacing: 0.53px;
    text-transform: uppercase;
  }
  .eligibility-check-status-submitted {
    border-radius: 5px;
    border: 1px solid var(--amber-accent-4, #ffab00);
    background: var(--amber-lighten-3, #ffe082);
    display: flex;
    padding: 7px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: var(--font-size-h3, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: 15.247px; /* 95.291% */
    letter-spacing: 0.53px;
    text-transform: uppercase;
  }
  .eligibility-check-status-rejected {
    border-radius: 5px;
    border: 1px solid var(--red-lighten-1, #ef5350);
    background: var(--red-lighten-4, #ffcdd2);
    display: flex;
    padding: 7px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: var(--font-size-h3, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: 15.247px; /* 95.291% */
    letter-spacing: 0.53px;
    text-transform: uppercase;
  }
}