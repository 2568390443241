.off-canvas {
    width: 840px !important;
    height: 100vh;
    padding: 1.4584vw;
    display: flex;
    gap: 1.0417vw;

    .spinner {
        width: 100%;
        height: 100%;
        display: flex !important;
        justify-content: center;
        align-items: center;
        max-height: unset !important;
    }

    ::-webkit-scrollbar {
        width: 8px;
        height: 4px;
        background: #D9D9D9;
    }

    ::-webkit-scrollbar-thumb {
        background: #979797;
        border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #adadad;
    }

    .offcanvas-header {
        padding: 0;
        display: flex;
        flex-direction: column-reverse;
        gap: 20px;

        .offcanvas-title {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;

            color: var(--Text-Color, #333);
            font-family: var(--font-family);
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .btn-close {
            position: relative;
            left: 48%;
        }
    }

    .offcanvas-body {
        padding: 0;

        .ant-spin-nested-loading,
        .ant-spin-nested-loading .ant-spin-container {
            height: 100%;
        }
    }

    .offcanvas-form {
        display: flex;
        flex-direction: column;
        gap: 1.0417vw;
        height: 100%;

        .profile-pic-preview {
            object-fit: cover;
            border-radius: 10px;
            width: 100px;
            height: 100px;
        }

        .default-pic {
            width: 58px;
            height: 58px;
            border-radius: 10px;
        }

        .add-icon {
            width: 24px;
        }

        .fields-container {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.25vw;
            overflow: auto;
        }

        .image-row {
            display: flex;
            width: 100%;
            align-items: end;
            padding: 1.0417vw 0 0;
        }

        .image-row> Button {
            color: #333;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.53px;
        }

        .form-row {
            display: flex;
            /* height: 73px; */
            width: 100%;
            align-items: flex-start;
            gap: 1.0417vw;
            align-self: stretch;

            color: #333;
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            .form-column {
                display: flex !important;
                flex-direction: column;
                gap: 8px;
                flex: 1 0;

                font-size: 16px;

                .input-header {
                    color: var(--Seconday-color, #B3B9BD);
                    display: flex;
                    gap: 4px;
                }

                .ant-select-selector {
                    font-size: 16px;
                }

                .form-input {
                    width: 381.5px;
                    height: 46px;
                    align-items: center;
                    align-self: stretch;
                    border-radius: 3px;
                    border: 1px solid #DADADA;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-family: Manrope;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                .ant-select-selection-placeholder {
                    color: var(--Secondary-Text-Color, #757575);
                    text-overflow: ellipsis;
                }

                .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-weight: 600;
                }

                .form-control {
                    display: flex !important;
                    width: 100% !important;
                    height: 46px;
                    align-items: center;
                    align-self: stretch;
                    border-radius: 3px;
                    border: 1px solid #DADADA;
                    background: #FFF;

                    font-weight: 600;
                }

                .form-text-area,
                .ant-picker .ant-picker-input>input {
                    font-family: Manrope;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
        }

        .ant-form-item .ant-form-item-control-input-content {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .staff-availability {
            color: #333;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 15.247px;
            /* 95.291% */
            letter-spacing: 0.53px;

            .day {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
                background: var(--Line, #BABABA);
                cursor: pointer;

                color: var(--Secondary-Text-Color, #757575);
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: Manrope;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.53px;
                text-transform: uppercase;
            }

            .day.active {
                background: var(--ai-main-color, #009DF7);
                color: white;
            }
        }

        .staff-timings {
            display: flex;
            align-items: center;
            gap: 8px;

            .ant-picker-outlined {
                display: inline-flex;
                height: 46px;
                padding: 12px;
                align-items: center;
                gap: 11px;
                flex-shrink: 0;
                border-radius: 3px;
                border: 1px solid #DADADA;
                background: #FFF;
            }

            .ant-picker .ant-picker-input>input {
                color: var(--Primary-Text-Color, #333);
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            span {
                color: var(--Seconday-color, #B3B9BD);
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .buttons-container {
            display: flex;
            justify-content: end;
            gap: 1.0417vw;
            padding: 0;

            .cancel-button {
                display: flex;
                min-width: 100px;
                height: unset;
                padding: 12px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 5px;
                border: 1px solid #006FE6;
                background: #FFF;

                color: #006FE6;
                text-align: center;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                /* 137.5% */
            }

            .add-button {
                display: flex;
                min-width: 100px;
                height: unset;
                padding: 12px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 5px;
                background: #006FE6;

                color: #FFF;
                text-align: center;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
            }
        }
    }
}

.ant-select-dropdown .ant-select-item-option-content {
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
}

.asterisk {
    color: red;
}

.react-tel-input .selected-flag .arrow {
    margin-top: -3px !important;
    width: 14px !important;
    height: 9px !important;
    border: none !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='9' viewBox='0 0 14 9' fill='none'%3E%3Cpath d='M2 2L7.25 8L12.5 2' stroke='%23333333' stroke-width='2' stroke-linecap='square' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
    border: unset !important;
    box-shadow: none !important;
}

.react-tel-input .form-control:focus {
    box-shadow: none !important;
}

.react-tel-input .country-list .country-name {
    margin-right: 0 !important;
}

.react-tel-input .country-list .country {
    position: relative;
    padding: 12px 9px 13px 46px;
    color: var(--Primary-Text-Color, #333);
    font-family: var(--font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    gap: 0;

    .dial-code {
        color: var(--primary-text-color) !important;
        font-family: var(--font-family) !important;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: normal;
    }

    .dial-code::before {
        content: '(';
    }

    .dial-code::after {
        content: ')';
    }
}

.react-tel-input .country-list .country.highlight {
    border-radius: 5px;
    background: #006fe6 !important;
    color: var(--White, #FFF);

    .dial-code {
        color: var(--White, #FFF) !important;
    }
}