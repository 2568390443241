.ant-select .ant-select-arrow {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.25);
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    inset-inline-start: auto;
    inset-inline-end: 11px;
    height: 12px;
    margin-top: -6px;
    font-size: 12px;
    pointer-events: none;
    transition: opacity 0.3s ease;
}

.ag-paging-panel {
    font-size: 16px;
    font-family: 'Manrope';
}

::-webkit-scrollbar {
    width: 8px;
    height: 4px;
    background: #D9D9D9;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    background: #979797;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #adadad;
}

.ant-message .ant-message-notice-wrapper {
  text-align: center;
  display: flex !important;
  justify-content: end !important;
  padding: 1.5625vw 2.6vw !important;
}

.ant-message .ant-message-notice-wrapper .ant-message-notice-success .ant-message-notice-content {
  display: flex !important;
  width: 400px;
  padding: 1.0417vw;
  align-items: center;
  gap: 0.83vw;
  border-radius: 0.52vw;
  border: 1px solid #4CAF50;
  background: #DAFEC5;
  box-shadow: 2px 2px 32px 0px rgba(0, 0, 0, 0.25);
}

.ant-message .ant-message-notice-wrapper .ant-message-notice-error .ant-message-notice-content {
  display: flex !important;
  width: 400px;
  padding: 1.0417vw;
  align-items: center;
  gap: 0.83vw;
  border-radius: 0.52vw;
  border: 1px solid var(--custom-error, #FF5252);
  background: var(--red-lighten-4, #FFCDD2);
  box-shadow: 2px 2px 32px 0px rgba(0, 0, 0, 0.25);
}

.ant-message .ant-message-notice-wrapper .ant-message-notice-warning .ant-message-notice-content {
  display: flex !important;
  width: 400px;
  padding: 1.0417vw;
  align-items: center;
  gap: 0.83vw;
  border-radius: 0.52vw;
  border: 1px solid var(--orange-darken-4, #E65100);
  background: var(--orange-lighten-4, #FFE0B2);
  box-shadow: 2px 2px 32px 0px rgba(0, 0, 0, 0.25);
}

.ant-message .ant-message-notice-wrapper .ant-message-custom-content {
  gap: 0.83vw;
  color: var(--Primary-Text-Color, #333);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: unset !important;
}

.ant-message .ant-message-notice-wrapper .ant-message-custom-content>.anticon {
  margin-inline-end: 0 !important;
  font-size: 18px !important;
}

.ag-watermark {
  display: none !important;
}

.ant-input-outlined.ant-input-disabled,
.ant-picker-input >input[disabled] {
  background-color: #F6F6F6;
  color: #BABABA !important;
  cursor: not-allowed;
}

.ant-select-disabled {
  background: rgba(0, 0, 0, 0.04);;
}

.ag-theme-quartz {
  --ag-borders: none;
}

.ag-horizontal-right-spacer {
  display: table;
}

.ag-paging-panel {
  font-size: 16px;
  font-family: 'Manrope';
}