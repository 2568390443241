.appointment-details-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 52px);
    padding: 1.5625vw 0 0;

    .appointment-details-view {
        height: 100%;
        width: 100%;
        display: flex !important;
        flex-direction: column !important;
        gap: 20px;
        padding: 0 1.5625vw;

        .appointment-details-header {
            display: flex;
            gap: 1.25vw;
            /* border-bottom: 1px solid var(--Line, #BABABA); */

            color: var(--Header-Text, #646464);
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Manrope;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.53px;

            .back-icon,
            .edit-icon {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }

            .appointment-info {
                display: flex !important;
                flex-direction: column !important;
                gap: 1.0417vw;
                width: 100%;
            }

            .appointment-basic-info {
                display: flex !important;
                flex-direction: column !important;
                gap: 0.417vw;
            }


            .appointment-id {
                display: flex;
                padding: 0.2vw 0.3125vw;
                justify-content: center;
                align-items: center;
                gap: 0.52vw;
                border-radius: 4px;
                background: var(--amber-lighten-4, #FFECB3);

                color: #8D6E63;
                font-family: Manrope;
                text-transform: uppercase;
            }

            .appointment-date-time {
                display: flex;
                gap: 0.20vw;

                color: #878787;
                font-family: Manrope;
                text-transform: uppercase;
            }

            .appointment-personal-info {
                display: flex;
                align-items: flex-start;
                gap: 1.0417vw;
                width: 100%;
                justify-content: space-between;

                .appointment-info-col {
                    display: flex !important;
                    flex-direction: column !important;
                    align-items: flex-start;
                    gap: 0.417vw;
                    flex: 1 0 0;

                    color: #333;
                    font-feature-settings: 'liga' off, 'clig' off;
                    font-family: Manrope;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0.085px;

                    .label {
                        color: #878787;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 15.247px;
                        letter-spacing: 0.53px;
                        text-transform: uppercase;
                    }

                    .patient-id {
                        display: flex;
                        padding: 0.2vw 0.3125vw;
                        justify-content: center;
                        align-items: center;
                        gap: 0.52vw;
                        border-radius: 4px;
                        background: var(--Secondary-Color, #D7EEFE);

                        color: var(--Primary-Color, #006FE6);
                        font-family: Manrope;
                        font-size: 14px;
                        line-height: 15.247px;
                        text-transform: uppercase;
                    }

                    .status {
                        padding: 0.25vw 0.52vw;
                        border-radius: 5px;
                        border: 1px solid var(--Status-Stroke, #97FF5B);
                        background: var(--Status-Color, #DAFEC5);

                        text-transform: uppercase;
                    }
                }
            }
        }

        .appointment-details-body {
            display: flex !important;
            flex-direction: column !important;
            gap: 1.25vw;
            padding: 0 0 0 2.5vw;

            color: var(--Primary-Text-Color, #333);
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            line-height: 15.247px;
            /* 95.291% */
            letter-spacing: 0.53px;

            .treatment-details-container {
                display: flex;
                padding: 24px 20px;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
                align-self: stretch;
                border-radius: 12px;
                border: 1px solid var(--Primary-Color, #006FE6);
                background: var(--White, #FFF);
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);

                .treatment-text {
                    font-size: 16px;
                    font-weight: 600;
                }

                .ant-table-wrapper {
                    width: 100%;
                }

                .ant-table-thead {
                    border-radius: 5px;
                }

                .ant-table-thead>tr>th {
                    padding: 14px;
                    background: var(--BG, #F3F4F8);

                    color: var(--Header-Text, #646464);
                    font-feature-settings: 'liga' off, 'clig' off;
                    font-family: Manrope;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    /* 142.857% */
                    letter-spacing: 0.53px;
                    text-transform: uppercase;
                }

                .ant-table-tbody>tr>td {
                    font-family: Manrope;
                    font-size: 16px;
                    text-transform: capitalize;
                }

                .ellipsis-cell {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 500px;
                }
            }
        }

    }
}