.staff-details-container {
  width: 100%;
  height: calc(100% - 52px);
  padding: 1.5625vw 0 0;

  .spinner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .staff-data-view {
    height: 100%;

    .staff-data-header {
      display: flex;
      padding: 0 1.5625vw;
      align-items: flex-start;
      gap: 1.25vw;
      align-self: stretch;

      .back-icon,
      .more-icon,
      .edit-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      .initials {
        width: 58px;
        height: 58px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        background: #d9d9d9;
        border-radius: 50%;

        color: #333;
        font-feature-settings: "liga" off, "clig" off;
        font-family: Manrope;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 15.247px;
        letter-spacing: 0.106px;
      }

      .profile-pic {
        width: 58px;
        height: 58px;
      }

      .options {
        display: flex;
        gap: 1.25vw;
      }
    }

    .divider {
      margin: 1.25vw 0 0;
      border-block-start: 1px solid #bababa;
    }

    .tabs-div.active {
      height: calc(100% - 1.25vw - 228px);
    }

    .tabs-div {
      height: calc(100% - 59px - 1.25vw);

      .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
        background: transparent;
        border: none;
        border-bottom: 1px solid var(--Line, #d9d9d9);
        border-radius: 0px;
        width: 306px;
        padding: 0 32px;
        height: 56px;
      }

      .ant-tabs,
      .ant-tabs-content-holder {
        height: 100%;
      }

      .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--shades-black, #000);
        font-feature-settings: "liga" off, "clig" off;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        /* 112.5% */
        letter-spacing: 0.53px;
      }

      .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
        color: #333;
        font-feature-settings: "liga" off, "clig" off;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 112.5% */
        letter-spacing: 0.53px;
      }

      .ant-tabs-nav-list {
        padding: 12px 0 0 0;
      }

      .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        width: 100%;
      }
    }
  }
}

.details-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.0417vw;
  flex: 1 0 0;

  .details-row {
    display: flex;
    align-items: flex-start;
    gap: 0.6vw;
    align-self: stretch;
  }

  .details-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 250px;
    gap: 8px;
    /* flex: 1; */

    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.2px;
    /* 101.25% */
    letter-spacing: 0.085px;

    .details-header {
      color: #878787;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 15.247px;
      /* 108.904% */
      letter-spacing: 0.53px;
      text-transform: uppercase;
    }

    .name {
      color: var(--Primary-Color, #006fe6);
      font-weight: 600;
    }
  }

  .staff-name {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16.2px;
    letter-spacing: 0.085px;
  }

  .staff-designation {
    color: #878787;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.709px;
    letter-spacing: 0.074px;
    text-transform: uppercase;
  }

  .staff-department,
  .staff-type {
    width: 230px;
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.2px;
    letter-spacing: 0.085px;
  }

  .staff-address {
    display: flex !important;
    flex-direction: column !important;

    width: 230px;
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.085px;
  }

  .availability-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 471px;

    .details-header {
      color: #878787;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 15.247px;
      /* 108.904% */
      letter-spacing: 0.53px;
      text-transform: uppercase;
    }

    .staff-availability,
    .staff-timings {
      display: flex;
      align-items: center;
      gap: 8px;

      color: #333;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 15.247px;
      /* 95.291% */
      letter-spacing: 0.53px;

      .day {
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        background: var(--Line, #bababa);

        color: var(--Secondary-Text-Color, #757575);
        font-feature-settings: "liga" off, "clig" off;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.53px;
        text-transform: uppercase;
      }

      .day.active {
        background: var(--ai-main-color, #009df7);
        color: white;
      }

      a {
        color: var(--Primary-Color, #006fe6);
        font-feature-settings: "liga" off, "clig" off;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16.2px;
        letter-spacing: 0.085px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
      }
    }
  }

  .staff-experience {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.53px;
  }

  .staff-status {
    display: flex;
    padding: 7px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid var(--Status-Stroke, #97ff5b);
    background: var(--Status-Color, #dafec5);
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: var(--font-size-h3, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: 15.247px;
    /* 95.291% */
    letter-spacing: 0.53px;
    text-transform: uppercase;
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    width: 100%;

    color: var(--Primary-Color, #006fe6);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.53px;

    span {
      display: flex !important;
      align-items: center;
      gap: 8px;
      max-width: 100%;
    }

    .phone-icon,
    .email-icon {
      width: 24px;
      height: 24px;
    }

    .copy-icon {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
}

.right-arrow {
  width: 24px;
  height: 24px;
}

.ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 0 !important;
  height: 100%;
}

.ant-tabs .ant-tabs-content {
  height: 100%;
}

.appointments-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .appointments-header {
    display: flex !important;
    height: 68px;
    padding: 0px 1.5625vw;
    align-items: center;
    gap: 1.5625vw;
    align-self: stretch;
    border-bottom: 1px solid var(--Line, #bababa);

    .appointment-text {
      flex: 1 0 0;
      color: #333;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 15.247px;
      /* 76.233% */
      letter-spacing: 0.53px;
    }

    .add-appointment {
      display: flex !important;
      align-items: center;
      gap: 6px;
      cursor: pointer;

      .add-icon {
        width: 24px;
        height: 24px;
      }

      .add-text {
        color: #333;
        font-feature-settings: "liga" off, "clig" off;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.247px;
        /* 95.291% */
        letter-spacing: 0.53px;
      }
    }

    .calendar-container {
      display: flex !important;
      width: 181px;
      justify-content: space-between;
      align-items: center;

      .form-control {
        color: #333;
        font-feature-settings: "liga" off, "clig" off;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 15.247px;
        letter-spacing: 0.53px;
        text-transform: uppercase;
        padding: 0;
        border: none;
      }

      .form-control:focus {
        box-shadow: none;
      }

      .react-datepicker__input-container {
        display: flex !important;
        position: unset !important;

        button {
          color: #333;
          font-feature-settings: "liga" off, "clig" off;
          font-family: Manrope;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 15.247px;
          letter-spacing: 0.53px;
          text-transform: uppercase;

          background: none;
          border: none;
          padding: 0;
        }
      }

      .calendar-icon,
      .down-arrow-icon {
        width: 24px;
        height: 24px;
        padding-right: 5px;
        position: unset;
      }
    }
  }

  .dates-slider {
    display: flex !important;
    padding: 0.63vw 1.5625vw;
    align-items: center;
    gap: 1.5625vw;
    align-self: stretch;
    border-bottom: 1px solid var(--Line, #bababa);

    .dates-container {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      /* gap: 1.5625vw; */
      flex: 1 0 0;

      .date-div {
        width: 30px;
        height: 61px;
        display: flex !important;
        flex-direction: column !important;
        gap: 15px;
        line-height: 1.5;
        cursor: pointer;

        .day-text {
          color: rgba(135, 135, 135, 0.85);
          font-feature-settings: "liga" off, "clig" off;
          font-family: Manrope;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 15.247px;
          /* 108.904% */
          letter-spacing: 0.53px;
          text-transform: uppercase;
        }

        .date {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex !important;
          justify-content: center;
          align-items: center;

          color: #333;
          font-feature-settings: "liga" off, "clig" off;
          font-family: Manrope;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 15.247px;
          /* 95.291% */
          letter-spacing: 0.53px;
        }

        .date.active {
          background: #006fe6;
          color: #fff;
        }
      }
    }

    .left-arrow,
    .right-arrow {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .appointment-list-container {
    height: calc(100% - 155px);
    overflow: auto;

    .no-appointments-container {
      span {
        color: #333;
        font-family: Manrope;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
      }
    }

    .no-appointments-container,
    .ant-spin-spinning {
      height: 100% !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    .appointment-row {
      display: flex !important;
      height: 81px !important;
      align-items: center;
      align-self: stretch;
      border-bottom: 1px solid var(--Line, #bababa);
    }

    .ant-col {
      color: #333;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 15.247px;
      letter-spacing: 0.53px;
      text-transform: capitalize;
    }

    .appointment-time-col {
      display: flex !important;
      width: 124px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-right: 1px solid var(--Line, #bababa);
    }

    .appointment-details-col {
      display: flex !important;
      flex-direction: column !important;
      padding: 18px 1.25vw;
      justify-content: center;
      align-items: flex-start;
      gap: 13px;
      flex: 1 0 0;
    }

    .appointment-info {
      display: flex !important;
      align-items: center;
      gap: 1.25vw;

      font-weight: 600;
    }

    .appointment-actions-col {
      display: flex !important;
      padding: 0px 1.25vw;
      align-items: center;
      gap: 10px;
      align-self: stretch;

      .view-details-text {
        cursor: pointer;
        color: var(--Primary-Color, #006fe6);
        font-weight: 600;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
      }

      .more-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
}

.ant-modal .ant-modal-content {
  padding: 28px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: 1.25vw;
  width: 100%;

  .close {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .header {
    color: var(--Text-Color, #333);
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-bottom: none;
    padding: 0;

    display: flex !important;
    align-items: center;
    justify-content: unset;
    gap: 8px;
    width: 100% !important;
    height: unset !important;
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 1.5625vw;

    .description {
      color: #333;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.53px;
    }
  }

  .modal-field {
    display: flex;
    flex-direction: column;
    gap: 8px;

    label {
      color: #b3b9bd;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .select-dropdown {
      height: 46px;
      background: #fff;
    }

    .ant-select .ant-select-selection-item {
      color: #333;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .ant-input {
      display: flex;
      min-height: 90px;
      padding: 12px 16px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 3px;
      border: var(--Number, 1px) solid #dadada;
      background: #fff;
    }
  }

  .info-icon {
    width: 24px;
    height: 24px;
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    .cancel {
      display: flex;
      width: 100px;
      min-width: 100px;
      height: 46px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 5px;
      border: var(--Number, 1px) solid var(--Primary-Color, #006fe6);
      background: var(--White, #fff);

      color: #006fe6;
      text-align: center;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .yes {
      display: flex;
      min-width: 100px;
      height: 46px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 5px;
      background: #006fe6;

      color: #fff;
      text-align: center;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .yes:hover {
      background: #006fe6 !important;
      color: #fff !important;
    }
  }
}

.ant-modal .ant-modal-footer {
  margin: 0;
}

.patients-container,
.documents-main-div {
  display: flex;
  flex-direction: column;
  height: 100%;

  .patients-header {
    display: flex !important;
    height: 68px;
    padding: 0px 1.5625vw;
    align-items: center;
    gap: 1.5625vw;
    align-self: stretch;
    border-bottom: 1px solid var(--Line, #bababa);

    .patients-text {
      flex: 1 0 0;
      color: #333;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 15.247px;
      /* 76.233% */
      letter-spacing: 0.53px;
    }

    .patient-dropdown {
      display: flex;
      width: 125px;
      height: 46px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    .ant-select-single {
      width: 100%;
      height: 100% !important;

      .ant-select-selection-item {
        color: #333;
        text-overflow: ellipsis;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .ant-select-dropdown {
      padding: 8px !important;
      gap: 8px;

      .ant-select-item-option-content {
        color: #333;
        font-feature-settings: "liga" off, "clig" off;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.53px;
      }
    }
  }

  .ant-spin-spinning {
    height: calc(100% - 68px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ag-theme-quartz {
    padding: 12px 0 12px 12px;
    height: 100%;
    --ag-borders: none;
    --ag-active-color: none;
    --ag-range-selection-border-color: #fff;

    .ag-column-last {
      width: 150px !important;
      justify-content: end;

      .ag-cell-wrapper {
        width: unset;
      }

      .ag-header-cell-label {
        justify-content: end;
      }
    }

    .ag-ltr .ag-cell {
      border-right-width: 1px;
      display: flex;
    }
  }

  .ag-pinned-right-header,
  .ag-pinned-right-cols-container {
    width: 150px !important;
    min-width: 150px !important;
  }

  .ag-header {
    border-radius: 5px 0px 0px 5px;
    background: var(--BG, #f3f4f8);
  }

  .ag-header-cell-sortable .ag-header-cell-label,
  .ag-header-cell-text {
    color: rgba(135, 135, 135, 0.85);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.53px;
    text-transform: uppercase;
  }

  .row-data-element {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: start;
    height: 100%;
    width: 100%;
    gap: 5px;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    line-height: 15.247px;
    letter-spacing: 0.53px;

    .name-container {
      display: flex !important;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 12px;

      .initials {
        width: 42.52px;
        height: 42.52px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        background: #d9d9d9;
        border-radius: 50%;

        color: #333;
        font-feature-settings: "liga" off, "clig" off;
        font-family: Manrope;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 15.247px;
        letter-spacing: 0.106px;
      }

      .full-name {
        width: calc(100% - 55px);
        color: var(--Primary-Color, #006fe6);
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.085px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
      }
    }

    .email {
      max-width: 100%;
      color: var(--Primary-Color, #006fe6);
      font-weight: 400;
      line-height: 22px;
      text-decoration-line: underline;
      cursor: pointer;
    }

    .phone {
      color: var(--Primary-Color, #006fe6);
      font-weight: 400;
    }

    .status {
      display: flex;
      padding: 7px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      text-align: center;
      color: #333;
      font-weight: 400;
      text-transform: uppercase;
    }

    .status-pending {
      border: 1px solid #ffb429;
      background: #ffdd9c;
    }

    .status-active {
      border: 1px solid var(--Status-Stroke, #97ff5b);
      background: var(--Status-Color, #dafec5);
    }

    .expired {
      color: #ff3e3e;
      line-height: 22px;
    }

    .login-pending {
      color: orange;
      line-height: 22px;
    }

    .plan {
      color: var(--Primary-Color, #006fe6);
    }

    .add-review-text {
      color: #006fe6;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
      word-wrap: break-word;
      cursor: pointer;
    }
  }
}

.ag-cell-wrapper {
  width: 100%;
  height: 100%;
}

.ellipsis-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.review-offcanvas {
  .fields-container {
    gap: 1.5625vw !important;
  }

  .patients-details-container {
    align-items: flex-start;
    gap: var(--font-size-h1, 24px);
    align-self: stretch;

    .patient-initials {
      width: 115px;
      height: 115px;
      background: #d9d9d9;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      color: #333;
      text-align: center;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 45px;
      letter-spacing: 0.212px;
    }

    .details-section {
      gap: 1.25vw;
    }

    .contact-info {
      width: 100%;

      .phone,
      .ec-name {
        color: #333;
      }
    }
  }

  .form-text-area {
    display: flex;
    height: 90px;
    padding: 12px 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  }

  .react-datepicker-wrapper,
  .form-input {
    width: 100%;
  }

  .calendar-icon {
    width: 24px;
    height: 24px;
  }
}

.notes-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .notes-header {
    display: flex;
    height: 68px;
    padding: 0px 1.5625vw;
    align-items: center;
    gap: 1.5625vw;
    align-self: stretch;
    border-bottom: 1px solid var(--Line, #bababa);

    .notes-text {
      flex: 1 0 0;
      color: #333;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Manrope;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 15.247px;
      /* 76.233% */
      letter-spacing: 0.53px;
    }

    .add-note {
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;

      .add-icon {
        width: 24px;
        height: 24px;
      }

      .add-note-text {
        color: #333;
        font-feature-settings: "liga" off, "clig" off;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.247px;
        letter-spacing: 0.53px;
      }
    }

    .ant-select-single {
      width: 100%;
      height: 100%;
    }
  }

  .ant-spin-spinning {
    height: calc(100% - 68px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ag-theme-quartz {
    padding: 12px 0 12px 12px;
    height: 100%;
    --ag-borders: none;
    --ag-active-color: none;
    --ag-range-selection-border-color: #fff;

    .ag-column-last {
      width: 150px !important;
      justify-content: end;

      .ag-cell-wrapper {
        width: unset;
      }

      .ag-header-cell-label {
        justify-content: end;
      }
    }

    .ag-ltr .ag-cell {
      border-right-width: 1px;
      display: flex;
    }
  }

  .ag-pinned-right-header,
  .ag-pinned-right-cols-container {
    width: 150px !important;
    min-width: 150px !important;
  }

  .ag-header {
    border-radius: 5px 0px 0px 5px;
    background: var(--BG, #f3f4f8);
  }

  .ag-header-cell-sortable .ag-header-cell-label,
  .ag-header-cell-text {
    color: rgba(135, 135, 135, 0.85);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.53px;
    text-transform: uppercase;
  }

  .row-data-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: 100%;
    width: 100%;
    gap: 5px;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    line-height: 15.247px;
    letter-spacing: 0.53px;

    .note-title {
      cursor: pointer;
      color: #006fe6;
      font-weight: 500;
      line-height: 20px;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
      text-transform: capitalize;
    }

    .attachments {
      span {
        color: #006fe6;
        font-weight: 600;
        cursor: pointer;
      }
    }

    .more-icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}

.ant-dropdown .ant-dropdown-menu {
  display: flex;
  width: 125px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid var(--Line, #bababa);
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 0;
  width: 100%;

  color: #333;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
  letter-spacing: 0.53px;
}

.ant-tooltip.ant-tooltip-placement-left {
  width: 473px !important;
  max-width: 473px !important;

  .ant-tooltip-inner {
    width: 473px;
    /* height: 610px; */
    padding: 0 !important;
    border-radius: 5px;
    border: 1px solid #bababa;
    background: #fff;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);

    .tooltip-container {
      padding: 0.83vw 1.5625vw 1.5625vw;

      .close-button {
        display: flex;
        justify-content: end;
      }

      .close-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      .data-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.83vw;

        color: #878787;
        font-feature-settings: "liga" off, "clig" off;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 15.247px;
        letter-spacing: 0.53px;
        text-transform: uppercase;

        .notes-data-header-row {
          display: flex;
          flex-direction: column;
          gap: 1.0417vw;

          .app-date {
            color: #333;
            font-size: 16px;
          }
        }

        .note-divider {
          margin: 0;
          background: #d9d9d9;
        }

        .notes-data-row {
          display: flex;
          flex-direction: column;
          gap: 0.63vw;
          flex: 1;

          .date {
            color: #333;
            font-size: 16px;
          }
        }

        .doctor-name {
          color: #006fe6;
          font-size: 16px;
          font-weight: 600;
          text-decoration-line: underline;
          text-decoration-style: solid;
          text-decoration-skip-ink: none;
          text-decoration-thickness: auto;
          text-underline-offset: auto;
          text-underline-position: from-font;
        }

        .app-notes {
          display: flex;
          flex-direction: column;
          gap: 1.5265vw;

          color: #333;
          font-feature-settings: "liga" off, "clig" off;
          font-family: Manrope;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22.2px;
          letter-spacing: 0.53px;
          text-transform: capitalize;

          span {
            font-weight: 600;
          }

          .note-desc {
            display: flex;
            flex-direction: column;
          }
        }

        .note-details {
          display: flex !important;
          flex-direction: column !important;

          color: #333;
          font-size: 16px;
          line-height: 22.2px;
        }

        .title {
          color: #333;
          font-size: 20px;
          font-weight: 600;
          line-height: 22px;
        }
      }
    }
  }
}

.ant-tooltip.ant-tooltip-placement-right {
  width: 473px !important;
  max-width: 473px !important;

  .ant-tooltip-inner {
    width: 473px;
    /* height: 472px; */
    padding: 0 !important;
    border-radius: 5px;
    border: 1px solid #bababa;
    background: #fff;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);

    .tooltip-container {
      padding: 0.83vw 1.5625vw 1.5625vw;

      .close-button {
        display: flex;
        justify-content: end;
      }

      .close-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      .data-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5265vw;

        color: #878787;
        font-feature-settings: "liga" off, "clig" off;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 15.247px;
        letter-spacing: 0.53px;
        text-transform: uppercase;

        .notes-data-row {
          display: flex;
          flex-direction: column;
          gap: 0.63vw;
          width: 100%;

          .date {
            color: #333;
            font-size: 16px;
          }
        }

        .note-details {
          color: #333;
          font-size: 16px;
          line-height: 22.2px;
        }

        .attachment-details {
          display: flex !important;
          justify-content: space-between;
          width: 100%;
          color: #757575;
          font-size: 16px;
          font-weight: 600;
        }

        .title {
          color: #333;
          font-size: 20px;
          font-weight: 600;
        }

        .dates-div {
          width: 100%;
          display: flex;
          gap: 1.5625vw;
        }
      }
    }
  }
}

.ant-tooltip.ant-tooltip-placement-bottom,
.ant-tooltip.ant-tooltip-placement-top {
    /* width: 163px !important;
    max-width: 163px !important; */

  .ant-tooltip-inner {
    padding: 5px !important;
    border-radius: 5px;

    .tooltip-container {
      padding: 12px 8px;

      .data-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5265vw;

        color: #878787;
        font-feature-settings: "liga" off, "clig" off;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 15.247px;
        letter-spacing: 0.53px;
        text-transform: uppercase;

        .notes-data-row {
          width: 100%;
          display: flex !important;
          gap: 0.4vw;
          justify-content: space-between;
        }

        .attachment-link {
          color: #333;
          font-size: 16px;
          line-height: 20px;
          width: 80%;
        }

        .download-icon {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }
  }
}

.custom-no-rows {
  font-size: 18px;
  font-family: "Manrope";
  font-weight: 400;
}
