.modal-content {
    width: 1092px !important;

    .ant-modal .ant-modal-content {
        display: flex;
        width: 100%;
        height: 70%;
        padding: 28px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 1.0417vw;
        width: 100%;
    }

    .modal-header {
        color: var(--Text-Color, #333);
        font-family: Manrope;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .data-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        /* flex: 1 0 0; */
    }

    .patient-details {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        align-self: stretch;

        height: 90px;
        padding: 16px 20px;
        border-radius: 12px;
        border: 1px solid var(--Primary-Color, #006FE6);
        background: var(--BG, #F3F4F8);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);

        .profile-pic {
            display: flex;
            padding: 0px 12px;
            justify-content: center;
            align-items: center;
            gap: 12px;
            align-self: stretch;
        }

        .modal-header-field {
            display: flex !important;
            flex-direction: column !important;
            align-items: flex-start;
            gap: 8px;
            width: 220px;

            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Manrope;
            font-style: normal;

            .label {
                color: #878787;
                font-size: 14px;
                font-weight: 400;
                line-height: 18.709px;
                letter-spacing: 0.074px;
                text-transform: uppercase;
            }

            .data {
                color: var(--Primary-Text-Color, #333);
                font-size: 16px;
                font-weight: 600;
                line-height: 16.2px;
                letter-spacing: 0.085px;
            }
        }
    }

    .date-time {
        display: flex;
        width: 518px;
        height: 73px;
        align-items: flex-start;
        gap: 20px;
    }

    .form-row {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 50%;

        .input-header {
            color: var(--Seconday-color, #B3B9BD);
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            gap: 4px;
        }

        .ant-select-selector {
            text-transform: capitalize;
        }

        .form-input {
            display: flex;
            height: 46px;
            align-items: center;
            align-self: stretch;
            border-radius: 3px;
            border: 1px solid #DADADA;
            /* background: #FFF; */

            overflow: hidden;
            color: var(--Text-Color, #333);
            text-overflow: ellipsis;
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            .calendar-icon {
                width: 24px;
                height: 24px;
            }
        }

        .ant-select-selection-placeholder {
            color: var(--Secondary-Text-Color, #757575);
            text-overflow: ellipsis;
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
            overflow: hidden;
            color: var(--Text-Color, #333);
            text-overflow: ellipsis;
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .form-control {
            display: flex;
            width: 100%;
            height: 46px;
            align-items: center;
            align-self: stretch;
            border-radius: 3px;
            border: 1px solid #DADADA;
            background: #FFF;

            color: var(--Text-Color, #333);
            font-family: var(--font-family);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

    }

    ::-webkit-scrollbar {
        width: 8px;
        height: 4px;
        background: #D9D9D9;
    }

    ::-webkit-scrollbar-thumb {
        background: #979797;
        border-radius: 4px;
    }

    .modal-form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        gap: 1.0417vw;

        .fields-container {
            display: flex;
            gap: 1.25vw;
            height: 100%;
            overflow: auto;

            .ant-form-item {
                width: 100%;
            }
        }

        .form-col {
            display: flex;
            flex-direction: column;
            width: 241px;
            gap: 8px;

            .input-header {
                color: var(--Seconday-color, #B3B9BD);
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                gap: 4px;
            }

            .form-input {
                display: flex;
                width: 100%;
                height: 46px;
                align-items: center;
                align-self: stretch;
                border-radius: 3px;
                border: 1px solid #DADADA;
                /* background: #FFF; */

                overflow: hidden;
                color: var(--Text-Color, #333);
                text-overflow: ellipsis;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                .calendar-icon {
                    width: 24px;
                    height: 24px;
                }
            }

            .ant-select-selection-placeholder {
                color: var(--Secondary-Text-Color, #757575);
                text-overflow: ellipsis;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
                overflow: hidden;
                color: var(--Text-Color, #333);
                text-overflow: ellipsis;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .form-control {
                display: flex;
                width: 100%;
                height: 46px;
                align-items: center;
                align-self: stretch;
                border-radius: 3px;
                border: 1px solid #DADADA;
                background: #FFF;

                color: var(--Text-Color, #333);
                font-family: var(--font-family);
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

        }

        .ant-form-item .ant-form-item-control-input-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .ant-picker .ant-picker-input>input,
        .ant-input-affix-wrapper>input.ant-input {
            color: var(--Text-Color, #333);
            text-overflow: ellipsis;
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .ant-picker-outlined {
            display: inline-flex;
            width: 165px;
            height: 46px;
            padding: 12px;
            align-items: center;
            gap: 11px;
            flex-shrink: 0;
            border-radius: 3px;
            border: 1px solid #DADADA;
            background: #FFF;
        }

        .ant-picker .ant-picker-input>input {
            color: var(--Primary-Text-Color, #333);
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .duration {
            width: 249px;
            height: 46px;
            padding: 12px;
            align-items: center;
        }

        .table-container {
            display: flex !important;
            flex-direction: column !important;
            gap: 10px;

            .ant-table-content {
                height: 358px;
                overflow: auto;
            }

            .ant-table-thead {
                border-radius: 5px;
            }

            .ant-table-thead>tr>th {
                padding: 14px;
                background: var(--BG, #F3F4F8);

                color: var(--Header-Text, #646464);
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: Manrope;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                /* 142.857% */
                letter-spacing: 0.53px;
                text-transform: uppercase;
            }

            .ant-table-tbody>tr {
                height: 74px !important;
            }

            .ant-table-tbody>tr .ant-table-cell {
                padding: 0 16px !important;
            }

            .ant-select {
                margin-top: 6px;
            }

            .ant-table-tbody>tr>td>div,
            .ant-table-tbody>tr>td>input {
                width: 163px;
                height: 42px;
                border-radius: 3px;
                border: 1px solid #DADADA;
                background: #FFF;

                overflow: hidden;
                color: var(--Text-Color, #333);
                text-overflow: ellipsis;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: capitalize;
            }

            .ant-select-selector input,
            .ant-select-selection-item {
                overflow: hidden;
                color: var(--Text-Color, #333);
                text-overflow: ellipsis;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .delete-icon {
                width: 24px;
                cursor: pointer;
            }

        }

        .treatment-header {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 12px;
            justify-content: space-between;
            align-self: stretch;

            .header {
                color: var(--Text-Color, #333);
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .add-treatment {
                display: flex;
                align-items: center;
                gap: 6px;
                border: none !important;
                cursor: pointer;

                img {
                    width: 24px;
                }

                .add-text {
                    color: #333;
                    font-feature-settings: 'liga' off, 'clig' off;
                    font-family: Manrope;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 15.247px;
                    /* 95.291% */
                    letter-spacing: 0.53px;
                }
            }
        }

        .buttons-container {
            display: flex;
            justify-content: end;
            gap: 1.0417vw;
            padding: 0;

            .cancel-button {
                display: flex;
                min-width: 100px;
                height: unset;
                padding: 12px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 5px;
                border: 1px solid #006FE6;
                background: #FFF;

                color: #006FE6;
                text-align: center;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                /* 137.5% */
            }

            .add-button {
                display: flex;
                min-width: 100px;
                height: unset;
                padding: 12px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 5px;
                background: #006FE6;

                color: #FFF;
                text-align: center;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
            }
        }

        .error-text {
            color: rgb(255, 0, 0);
        }
    }
}