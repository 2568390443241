.home-layout {
  height: 100vh;
  background: var(--BG, #f3f4f8);

  .main-breadcrumb {
    color: var(--Primary-Color, #006fe6);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.247px;
    /* 108.904% */
    letter-spacing: 0.53px;
    text-decoration-line: underline;
    text-transform: uppercase;
    cursor: pointer;
  }

  .dynamic-breadcrumb {
    color: var(--Primary-Text-Color, #333);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.247px;
    letter-spacing: 0.53px;
    text-transform: uppercase;
  }
}

.actions-container {
  display: flex !important;
  align-items: center;
  gap: 30px;
}

.notifications {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.quick-actions {
  color: #333;
  font-feature-settings: var(--font-feature-settings);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 15.247px;
  letter-spacing: 0.53px;
  text-transform: uppercase;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  img {
    width: 24px;
    height: 24px;
  }
}

.side-navbar-menu {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 30px 13px;
  /* font-feature-settings: "liga" off, "clig" off;
  font-family: Manrope;
  font-size: var(--font-size-h3, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: 15.247px;
  letter-spacing: 0.53px; */

  .ant-menu-item {
    gap: 12px;
    margin: 0 !important;
    padding: 8px 15px !important;
  }

  .ant-menu-title-content {
    color: var(--Primary-Text-Color, #333);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: var(--font-size-h3, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.53px;
  }

  .clinic-item {
    margin: 18px 0 !important;
  }

  .clinic-text {
    color: var(--Secondary-Text-Color, #979797);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.247px;
    /* 108.904% */
    letter-spacing: 0.53px;
    text-transform: uppercase;
  }
}

.ant-menu .ant-menu-item .ant-menu-item-icon + span {
  margin: 0 !important;
}

.home-content {
  display: flex;
  gap: 1.0417vw;
  margin: 1.0417vw 2.6vw 1.927vw;
  background: var(--BG, #f3f4f8);
}

.top-navbar {
  display: flex;
  height: 80px;
  padding: 1.563vw;
  margin: 1.927vw 2.6vw 0;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--Line, #d9d9d9);
  background: var(--White, #fff);
}

.logo {
  width: 100px;
}

.user {
  display: flex;
  gap: 10px;

  .user-profile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* align-items: end; */
  }

  .user-name {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.26px;
    /* 132.878% */
  }

  .user-role {
    color: #333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 14.457px;
    font-style: normal;
    font-weight: 600;
    line-height: 18.709px;
    /* 129.412% */
  }

  .profile-pic {
    width: 42px;
    height: 42px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.ant-layout-sider {
  flex: 0 0 256px !important;
  max-width: 256px !important;
  min-width: 256px !important;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: var(--White, #fff) !important;

  .ant-layout-sider-trigger {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    bottom: 98%;
    height: 24px !important;
    left: 95%;
    line-height: inherit !important;
    position: relative;
    width: 24px !important;
    z-index: 1;
  }

  .ant-menu {
    font-size: 16px;
  }

  .toggle-button {
    width: 256px;
    border: none;
  }

  .toggle-button-active {
    width: 80px;
    border: none;
  }

  .ant-menu-inline,
  .ant-menu-inline-collapsed {
    height: 100%;
    border-radius: 5px;
    gap: 5px;
  }
}

.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;

  .ant-layout-sider-trigger {
    width: 24px !important;
    left: 85% !important;
  }

  .ant-menu-item {
    margin: 0;
    padding: 8px 10px !important;
  }
}

.anticon {
  color: #000;
}

.ant-layout-sider {
  & .ant-layout-sider-trigger {
    .anticon svg {
      fill: #d9d9d9;
    }
  }
}

.ant-menu .ant-menu-item .ant-menu-item-icon {
  vertical-align: top !important;
}

.ant-layout .ant-layout-sider-has-trigger {
  padding-bottom: 18px;
}

.content {
  overflow: auto;
  border-radius: 5px;
  border: 1px solid var(--Line, #d9d9d9);
  background: var(--White, #fff);

  .breadcrumb {
    padding: 1.5625vw 1.5625vw 0;
    margin: 0;

    color: var(--Primary-Text-Color, #333);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.53px;
    text-transform: uppercase;
  }
}

.ant-menu-title-content {
  a {
    text-decoration: none;
  }
}

.side-navbar {
  height: 100%;
  background: #fff;

  .ant-layout .ant-layout-sider {
    background: transparent;
  }

  .menu-items {
    color: var(--Primary-Text-Color, #333);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Manrope;
    font-size: var(--font-size-h3, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: 15.247px;
    /* 95.291% */
    letter-spacing: 0.53px;
  }

  .menu-icon {
    width: 24px;
    height: 24px;
    /* margin: 7px 0 0 0 !important; */
  }

  .menu-items-selected {
    border-radius: 5px;
    background: var(--Secondary-Color, #d7eefe);

    span {
      color: var(--Primary-Color, #006fe6);
      font-weight: 600;
    }
  }
}

/* .ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  width: calc(100%);
} */

.ant-menu-item {
  display: flex;
  align-items: center;
}
